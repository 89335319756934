<template>
  <div>
    <v-sheet
      :height="height"
      :width="width"
      color="transparent"
      style="position: relative"
    >
      <v-img
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="require('@/assets/character/CS0000_default.png')"
      ></v-img>
      <v-img
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="require('@/assets/character/' + renderAvatar.body + '.png')"
      >
        <template v-slot:placeholder>
          <v-img
            class="avatar"
            :max-height="height"
            :max-width="width"
            :src="require('@/assets/entity/load.png')"
          />
        </template>
      </v-img>
      <v-img
        class="avatar"
        :max-height="height"
        :max-width="width"
        :style="
          'filter : opacity(0.5) drop-shadow(0 0 0 #' +
          renderAvatar.pColor +
          ' )'
        "
        :src="require('@/assets/character/' + renderAvatar.body + '.png')"
      ></v-img>
      <v-img
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="
          require('@/assets/character/' + renderAvatar.body + '_stroke.png')
        "
      >
        <template v-slot:placeholder>
          <v-img
            class="avatar"
            :max-height="height"
            :max-width="width"
            :src="require('@/assets/entity/load.png')"
          />
        </template>
      </v-img>
      <v-img
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="require('@/assets/character/CE0000_default.png')"
      ></v-img>
      <v-img
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="require('@/assets/character/' + renderAvatar.mouth + '.png')"
      ></v-img>
      <v-img
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="require('@/assets/character/CH0000_default.png')"
      ></v-img>
      <v-img
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="require('@/assets/character/CC0000_default.png')"
      ></v-img>
      <v-img
        v-if="avatarHat[0] && !changeHat && !user && previewHat == null"
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="require('@/assets/item/' + avatarHat[0].name)"
      ></v-img>
      <v-img
        v-if="user && pick.Pick && previewHat == null"
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="
          require('@/assets/item/' +
            hat.filter(function (entry) {
              return entry.value == pick.Pick;
            })[0].name)
        "
      ></v-img>
      <v-img
        v-if="previewHat != null"
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="
          require('@/assets/item/' +
            hat.filter(function (entry) {
              return entry.value == previewHat;
            })[0].name)
        "
      ></v-img>
    </v-sheet>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    avatar: {
      type: String,
    },
    width: {
      type: Number,
      default: 40,
    },
    height: {
      type: Number,
      default: 40,
    },
    changeHat: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Boolean,
      default: false,
    },
    previewHat: {
      type: String,
      default: null,
    },
  },
  computed: mapState({
    pick: (state) => state.pick.data,
  }),
  data: () => ({
    renderAvatar: null,
    avatarEar: null,
    avatarMouth: null,
    avatarColor: null,
    avatarHat: null,
    mouth: [
      { name: "CM0030_hamster.png", value: "CM0030_hamster" },
      { name: "CM0010_bear.png", value: "CM0010_bear" },
      { name: "CM0020_cat.png", value: "CM0020_cat" },
      { name: "CM0000_rabbit.png", value: "CM0000_rabbit" },
    ],
    ear: [
      { name: "CB0030_hamster_stroke.png", value: "CB0030_hamster" },
      { name: "CB0010_bear_stroke.png", value: "CB0010_bear" },
      { name: "CB0020_cat_stroke.png", value: "CB0020_cat" },
      { name: "CB0000_rabbit_stroke.png", value: "CB0000_rabbit" },
    ],
    color: [
      { name: "CB0030_hamster.png", value: "CB0030_hamster" },
      { name: "CB0010_bear.png", value: "CB0010_bear" },
      { name: "CB0020_cat.png", value: "CB0020_cat" },
      { name: "CB0000_rabbit.png", value: "CB0000_rabbit" },
    ],
    hat: [
      { name: "H000010_sprout.png", value: "H000010" },
      { name: "H000020_mushroom.png", value: "H000020" },
      { name: "H000030_popcandy.png", value: "H000030" },
      { name: "H000040_paperplane.png", value: "H000040" },
      { name: "H000050_pencil.png", value: "H000050" },
      { name: "H000060_flower.png", value: "H000060" },
      { name: "H000070_windmill.png", value: "H000070" },
      { name: "H000080_blackcap.png", value: "H000080" },
      { name: "H000090_fishhead.png", value: "H000090" },
      { name: "H000100_fishtail.png", value: "H000100" },
      { name: "H000110_fishred.png", value: "H000110" },
      { name: "H000120_rottoenapple.png", value: "H000120" },
      { name: "H000130_applegreen.png", value: "H000130" },
      { name: "H000140_apple.png", value: "H000140" },
      { name: "H000150_goldenapple.png", value: "H000150" },
      { name: "H000160_bluewhale.png", value: "H000160" },
      { name: "H000170_crab.png", value: "H000170" },
      { name: "H000180_starfish.png", value: "H000180" },
    ],
    selectedStudent: null,
    student: null,
    code: null,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
  }),
  beforeMount() {
    this.renderAvatar = JSON.parse(this.avatar);
    var ear = this.renderAvatar.body;
    var mouth = this.renderAvatar.mouth;
    var hat = this.renderAvatar.hat;
    this.avatarEar = this.ear.filter(function (entry) {
      return entry.value == ear;
    });
    this.avatarMouth = this.mouth.filter(function (entry) {
      return entry.value == mouth;
    });
    this.avatarColor = this.color.filter(function (entry) {
      return entry.value == ear;
    });
    this.avatarHat = this.hat.filter(function (entry) {
      return entry.value == hat;
    });

    if (this.avatarHat[0] && this.user) {
      this.$store.commit("updatePick", {
        Pick: this.avatarHat[0].value,
      });
    }
  },
};
</script>

<style scoped>
.avatar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>