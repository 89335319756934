var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-sheet',{staticStyle:{"position":"relative"},attrs:{"height":_vm.height,"width":_vm.width,"color":"transparent"}},[_c('v-img',{staticClass:"avatar",attrs:{"max-height":_vm.height,"max-width":_vm.width,"src":require('@/assets/character/CS0000_default.png')}}),_c('v-img',{staticClass:"avatar",attrs:{"max-height":_vm.height,"max-width":_vm.width,"src":require('@/assets/character/' + _vm.renderAvatar.body + '.png')},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-img',{staticClass:"avatar",attrs:{"max-height":_vm.height,"max-width":_vm.width,"src":require('@/assets/entity/load.png')}})]},proxy:true}])}),_c('v-img',{staticClass:"avatar",style:('filter : opacity(0.5) drop-shadow(0 0 0 #' +
        _vm.renderAvatar.pColor +
        ' )'),attrs:{"max-height":_vm.height,"max-width":_vm.width,"src":require('@/assets/character/' + _vm.renderAvatar.body + '.png')}}),_c('v-img',{staticClass:"avatar",attrs:{"max-height":_vm.height,"max-width":_vm.width,"src":require('@/assets/character/' + _vm.renderAvatar.body + '_stroke.png')},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-img',{staticClass:"avatar",attrs:{"max-height":_vm.height,"max-width":_vm.width,"src":require('@/assets/entity/load.png')}})]},proxy:true}])}),_c('v-img',{staticClass:"avatar",attrs:{"max-height":_vm.height,"max-width":_vm.width,"src":require('@/assets/character/CE0000_default.png')}}),_c('v-img',{staticClass:"avatar",attrs:{"max-height":_vm.height,"max-width":_vm.width,"src":require('@/assets/character/' + _vm.renderAvatar.mouth + '.png')}}),_c('v-img',{staticClass:"avatar",attrs:{"max-height":_vm.height,"max-width":_vm.width,"src":require('@/assets/character/CH0000_default.png')}}),_c('v-img',{staticClass:"avatar",attrs:{"max-height":_vm.height,"max-width":_vm.width,"src":require('@/assets/character/CC0000_default.png')}}),(_vm.avatarHat[0] && !_vm.changeHat && !_vm.user && _vm.previewHat == null)?_c('v-img',{staticClass:"avatar",attrs:{"max-height":_vm.height,"max-width":_vm.width,"src":require('@/assets/item/' + _vm.avatarHat[0].name)}}):_vm._e(),(_vm.user && _vm.pick.Pick && _vm.previewHat == null)?_c('v-img',{staticClass:"avatar",attrs:{"max-height":_vm.height,"max-width":_vm.width,"src":require('@/assets/item/' +
          _vm.hat.filter(function (entry) {
            return entry.value == _vm.pick.Pick;
          })[0].name)}}):_vm._e(),(_vm.previewHat != null)?_c('v-img',{staticClass:"avatar",attrs:{"max-height":_vm.height,"max-width":_vm.width,"src":require('@/assets/item/' +
          _vm.hat.filter(function (entry) {
            return entry.value == _vm.previewHat;
          })[0].name)}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }